<template>
  <div>
    <v-dialog
      scrollable
      :value="status"
      persistent
      max-width="650px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>Invite a user to your business</v-card-title>
        <v-card-subtitle v-if="step === 1">Check to see if user data exists within database</v-card-subtitle>
        <v-card-subtitle v-if="existingUser!== null">Existing user found</v-card-subtitle>
        <v-card-text>
          <div v-if="step === 1">
            <v-form @submit.prevent ref="emailForm">
              <v-text-field
                v-model="checkEmail"
                :rules="emailRules"
                prepend-inner-icon="email"
                name="email"
                label="Email"
              ></v-text-field>
            </v-form>
          </div>
          <div v-else>
            <v-form ref="form">
              <v-text-field
                validate-on-blur
                :rules="nameRules"
                :disabled="existingUser !== null"
                v-model="user.first_name"
                prepend-inner-icon="account_circle"
                name="first_name"
                label="First Name"
              ></v-text-field>
              <v-text-field
                validate-on-blur
                :rules="nameRules"
                :disabled="existingUser !== null"
                v-model="user.last_name"
                prepend-inner-icon="account_circle"
                name="last_name"
                label="Last Name"
              ></v-text-field>
              <v-text-field
                validate-on-blur
                :disabled="existingUser !== null"
                v-model="user.email"
                :rules="emailRules"
                prepend-inner-icon="email"
                name="email"
                label="Email"
              ></v-text-field>
              <v-text-field
                v-if="existingUser === null"
                :disabled="existingUser !== null"
                v-model="user.password"
                :rules="passwordRules"
                prepend-icon="lock"
                name="password"
                label="Password"
                id="CurrentPassword"
                validate-on-blur
                :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <v-text-field
                v-if="existingUser === null"
                :disabled="existingUser !== null"
                v-model="user.confirm_password"
                :rules="checkPassword"
                prepend-icon="lock"
                name="password"
                label="Current Password"
                id="CurrentPassword"
                validate-on-blur
                :append-icon="showConfirmPassword ? 'visibility_off' : 'visibility'"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
              ></v-text-field>
            </v-form>
            <br />
            <br />
            <v-layout mx-1 row wrap align-center align-baseline mb-2>
              <v-icon size="20" class="mr-4">fas fa-cog</v-icon>
              <p class="title pa-0 ma-0">Advance Configuration</p>
            </v-layout>
            <v-checkbox
              v-if="existingUser===null"
              label="User must create a new password on first log on"
              hint="If unchecked, user will use the same password entered above"
              persistent-hint
              v-model="user.set_new_password"
            ></v-checkbox>
            <v-checkbox
              persistent-hint
              hint="If unchecked, user will have to be provided with the details manually"
              label="Send an invitation email to the user."
              v-model="user.send_email"
            ></v-checkbox>
            <v-checkbox
              persistent-hint
              hint="User will have access to top level organization settings and communications."
              label="Give corporate access to the user"
              v-model="user.is_corporate"
            ></v-checkbox>
            <v-checkbox
              hint="User will have admin access to the application"
              label="Admin Acess"
              persistent-hint
              v-model="user.admin_access"
            ></v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="step === 1">
            <v-btn @click="step = 2" color="darkGrey" text class="text-capitalize">Skip</v-btn>
            <v-btn
              @click="checkUserExist"
              elevation="0"
              color="primary"
              class="text-capitalize"
            >Next</v-btn>
          </div>
          <div v-else>
            <v-btn @click="closeModal" color="darkGrey" text class="text-capitalize">Cancel</v-btn>
            <v-btn
              elevation="0"
              color="primary"
              class="text-capitalize"
              @click="handleNewUserCreation"
            >Confirm</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { AuthAPI } from '../../clients/auth/auth.api'
export default {
  props: {
    status: {
      type: Boolean
    }
  },
  data () {
    return {
      step: 1,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Password must be more than 8 characters'
      ],
      nameRules: [
        v => !!v || 'Name is required'
      ],
      checkPassword: [
        v => v === this.user.password || 'Passwords do not match'
      ],
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        confirm_password: '',
        set_new_password: false,
        is_corporate: false,
        admin_access: false,
        send_email: false
      },
      showPassword: false,
      showConfirmPassword: false,
      checkEmail: '',
      existingUser: null
    }
  },
  methods: {
    async checkUserExist () {
      let response
      try {
        response = await AuthAPI.checkIfUserExist({ email: this.checkEmail })
      } catch (error) {
        if (error.response.status === 404) {
          this.user.email = this.checkEmail
          this.step = 2
          this.swal({
            title: 'Not Found',
            text: 'User not found. Please create a new user',
            type: 'info'
          })
        } else if (error.response.status === 409) {
          this.swal({
            title: 'User Found',
            text: 'User is already a part of your business.',
            type: 'info'
          })
        }
        return
      }
      this.existingUser = response.data.payload
      this.user.first_name = this.existingUser.first_name
      this.user.last_name = this.existingUser.last_name
      this.user.email = this.existingUser.email
      this.user.phone = this.existingUser.phone
      this.step = 2
    },
    async inviteNew () {
      // validate form
      if (!this.$refs['form'].validate()) {
        return console.log('form not valid')
      }
      this.spinner(true)
      let response
      try {
        response = await AuthAPI.inviteANewUser(this.user)
      } catch (error) {
        return console.log(error.response)
      }
      await this.$emit('fetch-all-users')
      this.spinner(false)
      this.closeModal()
      this.swal({
        title: 'Success',
        text: 'User is invited to your business.',
        type: 'success'
      })
    },
    async handleNewUserCreation () {
      if (this.existingUser === null) {
        await this.inviteNew()
      } else {
        await this.inviteExisting()
      }
    },
    async inviteExisting () {
      let data = {
        'is_corporate': this.user.is_corporate,
        'admin_access': this.user.admin_access,
        'send_email': this.user.send_email
      }
      this.spinner(true)
      let response
      try {
        response = await AuthAPI.inviteExistingUser(this.existingUser.id, data)
      } catch (error) {
        return
      }
      await this.$emit('fetch-all-users')
      this.swal({
        title: 'Success',
        text: 'User is invited to your business.',
        type: 'success'
      })
      this.closeModal()
      this.spinner(false)
    },
    closeModal () {
      this.$emit('close-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
